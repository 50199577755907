<template>
  <div class="create-new-account">
    <b-overlay :show="loadingCreateAccount" rounded="sm">
      <button class="create-new-account__back-button" @click="changeView('Accounts')">
        <i class="ion ion-chevron-back-outline"></i>
        {{ $t("userManagement.back") }}
      </button>
      <CreateAccountInputs
        v-model="accountForm"
        :createAccountFromLogin="false"
        ref="create-account-inputs-data"
      />
      <button
        class="create-new-account__save-button"
        type="submit"
        :disabled="loadingCreateAccount"
        v-loading="loadingCreateAccount"
        @click="createAccount"
      >
        <p class="mb-0">
          {{ $t("createAccount.button") }}
        </p>
      </button>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import changeTypePasswords from "@/Extend/changeTypePasswords";
import { generalMessage, errorMessage } from "../../../../utils/ErrorMessages";
import errorValidation from "../../../../Extend/ValidationCreateAccount";
import CreateAccountInputs from "../../../CreateAccount/CreateAccountInputs.vue";

export default {
  name: "CreateNewAccount",
  components: {
    CreateAccountInputs,
  },
  mixins: [errorValidation, changeTypePasswords],
  data() {
    return {
      accountForm: {
        name: "Name",
        company_name: "",
        company_address: "",
        phone: "",
        first_name: "",
        lastname: "",
        email: "",
        country_code: "",
        zip_code: "",
        city: "",
        state_code: "",
        tax_id: "",
        accounting: "",
        website: "",
        password: "",
        confirm_password: "",
      },
      email_updates: false,
      checkedTerms: true,
      loadingCreateAccount: false,
    };
  },
  methods: {
    async validateEmailExist() {
      const response = await this.$store.dispatch("user/validateIfTheEmailExist", {
        username: this.accountForm.email.toLowerCase(),
      });
      if (!response) {
        errorMessage("The email address you entered is already in use. Please use a different email or log in with your existing account.");
      }
      return response;
    },
    async createAccount() {
      const validateEmail = await this.validateEmailExist();
      if (!validateEmail) {
        this.loading = false;
        return;
      }
      const isValidForm = await this.$refs["create-account-inputs-data"].validateForm();
      const passwordMatch = this.passwordsMatch();
      const passwordStrong = this.strongPassword();
      if (isValidForm && passwordMatch && passwordStrong) {
        this.loadingCreateAccount = true;
        const payload = { ...this.accountForm };
        payload.country_code = this.accountForm.country_code.name;
        payload.email_updates = this.email_updates;
        payload.accept_terms = this.checkedTerms;
        payload.account_id = this.accountForm.accounts.id;
        payload.account_name = this.accountForm.accounts.name;
        delete payload.accounts;
        const response = await this.$store.dispatch("user/createAccount", payload);
        if (response !== undefined) {
          generalMessage("Your request is in process!");
          this.$store.commit("account/resetAccounts");
          await this.$router.push({ name: "Accounts" });
        }
      }
      this.loadingCreateAccount = false;
    },
    changeView(view) {
      if (this.$route.name !== view) {
        this.$router.push({ name: view });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-new-account {
  font-family: $font-family-portal;
  padding: 4%;
  @media (max-width: 800px) {
    width: 97%;
    margin: auto;
    padding: 2% 0;
  }
  &__back-button {
    @include button-back;
    display: flex;
    padding-bottom: 1rem;
  }
  &__save-button {
    @include primary-button(40px);
    margin-top: 2%;
    width: 25%;
    @include font-button-text;
    @media (max-width: 800px) {
      width: 96%;
    }
  }
}

::v-deep {
  .bg-light {
    width: 102%;
    background-color: $color-white !important;
  }
  .el-loading-mask {
    z-index: 10;
  }
}
</style>
